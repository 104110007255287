/* 全体の基本スタイル */
:local {
  .body {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
    margin: 0;
    padding: 0;
    background-color: #f9f9f9;
    display: flex;
    flex-direction: column;
  }

  /* Main Content Area */
  .content {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }

  .content h2 {
    color: #000000;
    font-size: 1.2em;
    margin-top: 0;
  }

  .content p {
    margin-bottom: 1em;
  }

  .content ul,
  .content ol {
    margin: 10px 0 20px 20px;
    padding: 0;
  }

  .content li {
    margin-bottom: 8px;
  }

  /* 各FAQブロックのスタイル */
  .faq_block {
    border-bottom: 1px solid #ddd;
    padding: 15px 0;
  }

  /* 質問部分のスタイル */
  .question {
    font-weight: bold;
    font-size: 1.1em;
    margin-bottom: 10px;
    cursor: pointer;
    position: relative;
    padding-right: 20px;
  }

  /* 質問部分のアイコン */
  /* .question::after {
    content: '+';
    position: absolute;
    right: 0;
    top: 0;
    font-size: 1.2em;
    color: #007BFF;
} */

  /* 回答部分のスタイル */
  .answer {
    font-size: 1em;
    line-height: 1.6;
    padding-left: 10px;
    border-left: 3px solid #007bff;
    color: #555;
  }
  /* display: none; 初期状態では非表示 */

  /* 質問をクリックしたときのスタイル */
  .faq_block.active .question::after {
    content: "-";
  }

  .faq_block.active .answer {
    display: block;
    animation: fadeIn 0.3s ease-in-out;
  }

  /* フェードインアニメーション */
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* ホバー時の効果 */
  .question:hover {
    color: #007bff;
  }

  /* レスポンシブ対応 */
  @media (max-width: 600px) {
    .content {
      padding: 10px;
    }

    .question {
      font-size: 1em;
    }

    .answer {
      font-size: 0.95em;
    }
  }

  .main_content {
    flex: 1;
  }

  /* Footer */
  .footer {
    background-color: #f1f8f5;
    color: #fff;
    text-align: center;
    padding: 0;
    flex-shrink: 0;
  }

  .footer_inner {
    height: 120px;
  }

  .footer_lang {
    background-color: #888;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #888;
    height: 60px;
    width: 100%;
    margin: 0; /* 不要な余白をなくす */
  }

  .footer p {
    margin: 0;
    font-size: 0.9em;
  }

  /* リンクのスタイル */
  .a {
    color: #0073e6;
    text-decoration: none;
  }

  .a:hover {
    text-decoration: underline;
  }

  .content h1 {
    font-size: 2em;
  }

  /* exit_commentの中央配置 */
  .exit-root {
    display: flex;
    justify-content: center; /* 水平方向の中央揃え */
    align-items: center; /* 垂直方向の中央揃え */
    height: calc(100vh - 60px); /* フッターを除いた高さ */
    text-align: center; /* テキスト中央揃え */
    padding: 20px;
    box-sizing: border-box;
  }
  .exit_comment p {
    margin: 0;
    line-height: 1.6;
    color: #333;
  }

  /* メディアクエリ（レスポンシブ対応） */
  @media (max-width: 600px) {
    .content {
      padding: 15px;
      margin: 10px;
    }

    .header h1 {
      font-size: 1.5em;
    }

    .content h1 {
      font-size: 2em;
    }

    .content h2 {
      font-size: 1.2em;
    }
  }
}
